D.addEventListener("DOMContentLoaded", function() {

		//faq 
		const faqItem = D.querySelectorAll(".js-faq-item__top");

		faqItem.forEach(current => {
			current.addEventListener('click', function(e) {
				let container = current.closest('.js-faq-item');
				
				container.classList.toggle('is-active')
			})
		})

	//header mobile submenu 

	let subNav = D.querySelectorAll('.js-mobile-subnav'),
		subNavClose =  D.querySelector('.js-mobile-subnav-close');

	
		if(subNav) {
			subNav.forEach(current => {
				current.addEventListener('click', function(e) {
					if (W.innerWidth <= 992) {
						current.classList.add('is-active');
						subNavClose.classList.add('is-active');
						nav.classList.add('is-active-mobile');
					}
				})
			})
		
		
			const mobileMenuSubClose = () => { 
				if(subNavClose) {
					Array.from(subNav).forEach(item => {
						item.classList.remove('is-active')
					})
					subNavClose.classList.remove('is-active');
					nav.classList.remove('is-active-mobile');
				}
			}
		
			subNavClose.addEventListener('click', mobileMenuSubClose);
		}


	//mobile menu
		let nav = D.querySelector('.js-nav'),
			burger = D.querySelector('.js-burger'),
			logo = D.querySelector('.js-logo');

		const mobileMenu = () => {
			if (W.innerWidth <= 992) {
				nav.classList.toggle('is-active');
				burger.classList.toggle('is-active');
				logo.classList.toggle('is-active');
			}
			mobileMenuSubClose();
		}

		burger.addEventListener('click', mobileMenu);

		

	let linksScrollTo = D.querySelectorAll('.js-scrollto');

	linksScrollTo.forEach(current => {
		current.addEventListener('click', function(e) {
			e.preventDefault();

			let urlH = current.getAttribute("href"),
				element = D.querySelector(urlH);

			element.scrollIntoView({behavior: "smooth"});

		})
	})


	//header memu scroll to
	const sections = D.querySelectorAll(".js-scroll-section");

	W.addEventListener("scroll", navHighlighter);

	function navHighlighter() {
		let scrollY = W.pageYOffset;

		sections.forEach(current => {
			const sectionHeight = current.offsetHeight;
			const sectionTop = current.getBoundingClientRect().top + W.pageYOffset - 60
			let sectionId = current.getAttribute("id");

			if ( sectionTop <= scrollY && sectionTop + sectionHeight > scrollY ) {
				D.querySelector(`.js-scrollto[href*= ${sectionId} ]`).classList.add("is-active");
			} else {
				D.querySelector(`.js-scrollto[href*= ${sectionId} ]`).classList.remove("is-active");
			}
		});
	}

	



	//animated block
		let container = D.querySelector('.js-article-container');
		let stickySidebar = D.querySelector('.js-block-sticky');

		D.addEventListener('scroll', function() {
			if (stickySidebar) {
				stickify(container, stickySidebar);
			}
		});

		function stickify(wrapper, stickyEl) {
		  let wrapperRect = wrapper.getBoundingClientRect();
		  let stickyRect = stickyEl.getBoundingClientRect();
		  let windowHeight = window.innerHeight - 100;
		  
		  if (wrapperRect.bottom < windowHeight) {
			stickyEl.classList.remove('fixed');
		  } else if (wrapperRect.top < 0) {
			stickyEl.classList.add('fixed');
			stickyEl.classList.add('fixed-g');
		  } else if (stickyRect.top <= wrapperRect.top) {
			stickyEl.classList.remove('fixed');
			stickyEl.classList.remove('fixed-g');
		  }
		}


	
	const header = D.querySelector('.js-header');
	//fixed header
	const fixedHeader = () => {
		
		let scrollBarPosition = W.pageYOffset | D.body.scrollTop;

		if( scrollBarPosition  > 70) {
			header.classList.add("is-fixed")
		} else {
			header.classList.remove("is-fixed")
		}
	}

	fixedHeader();
	W.addEventListener("scroll", fixedHeader);


});

