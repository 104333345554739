W.onload = function () {

   const forms = D.querySelectorAll(".js-form");

   forms.forEach(current => {
   		let pristine = new Pristine(current);

   		current.addEventListener('submit', async (e) => {
	      e.preventDefault();
	      let valid = pristine.validate();

	      if (valid) {
	      	let urlLink = current.getAttribute('action');
	      	let urlThank = current.getAttribute('data-thank');
	      	let urlRedirect = current.getAttribute('data-redirect');

	      	let serializeForm = function (current) {
				let obj = {};
				let formData = new FormData(current);
				for (let key of formData.keys()) {
					obj[key] = formData.get(key);
				}
				return obj;
			};


		    await fetch(urlLink, {
				method: 'POST',
				body: JSON.stringify(serializeForm(event.target)),
				headers: {
					'Content-type': 'application/json; charset=UTF-8'
				}
			}).then(function (response) {
				if (response.ok) {
					console.log('successfully');
					console.log(urlRedirect)
					if (urlRedirect) {
						document.location.href = urlRedirect
						
					}
				}
			}).then(function (data) {
				console.log(data);
			}).catch(function (error) {
				console.warn(error);
			});
   		}

		});

   	});

}
