class Tabs {
	constructor(tabContainer) {
		this.tabContainer = tabContainer;
		this.tabLinks     = this.tabContainer.querySelectorAll('[data-tab="button"]');
		this.tabContent   = this.tabContainer.querySelectorAll('[data-tab="content"]');
		this.tabName     = this.tabContainer.querySelectorAll('[data-tab="name"]');

		this.tabLinks.forEach((el) => {
			el.addEventListener('click', (e) => this.openTabs(e));
		});

		this.tabName.forEach((el) => {
			el.addEventListener('click', (e) => this.openTabsMobile(e));
		});

	}

	openTabs(el) {
		const btnTarget = el.currentTarget;
		const item = btnTarget.dataset.id;

		this.tabContent.forEach((el) => {
			el.classList.remove('is-active');
		});

		this.tabLinks.forEach((el) => {
			el.classList.remove('is-active');
		});

		this.tabContainer.querySelector(`#${item}`).classList.add('is-active');
		btnTarget.classList.add('is-active');

	}

	openTabsMobile(el) {
		const btnTarget = el.currentTarget;
		const unit = btnTarget.closest('[data-tab="content"]');
		let mobileWrap = unit.querySelector('[data-tab="wrap"]');

		mobileWrap.classList.toggle('is-active');
		btnTarget.classList.toggle('is-active');

	}
}

//Tabs
const tabContainer = document.querySelectorAll('[data-tab="tabs"]');
tabContainer.forEach((tabContainer) => new Tabs(tabContainer));