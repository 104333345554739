D.addEventListener("DOMContentLoaded", function() {

	let $photoGallerySlider = new Swiper('.js-news-slider', {
		slidesPerView: 3,
		spaceBetween: 20,
		loop: false,
		speed: 1200,
		watchSlidesVisibility: true,
		pagination: {
			el: '.swiper-pagination',
			clickable: true
		},
		navigation: {
		  nextEl: ".js-news-slider__next",
		  prevEl: ".js-news-slider__prev",
		},
		breakpoints: {
			480: {
				slidesPerView: 2,
				spaceBetween: 18,
			},
			768: {
				slidesPerView: 2,
			},
		},
	});

	let $reviewsSlider= new Swiper('.js-reviews-slider', {
		slidesPerView: 1,
		spaceBetween: 0,
		loop: false,
		speed: 900,
		watchSlidesVisibility: true,
		effect: "fade",
		navigation: {
		  nextEl: ".js-reviews-slider__next",
		  prevEl: ".js-reviews-slider__prev",
		},
	});

	let $partnersSlider= new Swiper('.js-partners-slider', {
		slidesPerView: 'auto',
		spaceBetween: 70,
		loop: false,
		speed: 900,
		watchSlidesVisibility: true,
		navigation: {
		  nextEl: ".js-partners-slider__next",
		  prevEl: ".js-partners-slider__prev",
		},
		breakpoints: {
			577: {
				spaceBetween: 35,
			},
		},
	});

});