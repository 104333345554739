D.addEventListener("DOMContentLoaded", function() { 

	let popupLink = D.querySelectorAll('.js-popup-link'),
		popups = D.querySelectorAll('.js-popup'),
		popupClose = D.querySelectorAll('.js-popup-close'),
		iframe = document.querySelector('.js-iframe'),
		lastOpenedPopup = null,
		scrollPosition;

	popupLink.forEach(current => {
		current.addEventListener('click', function(e){
			e.preventDefault();
			let activePopup = current.dataset.link;
			let videoUrl = current.dataset.video;
			openPopup(activePopup);

			if(videoUrl) {
				console.log(videoUrl)
				iframe.setAttribute('src', videoUrl)
			}
		})
	})

	function openPopup(target){
		const elem = D.querySelector('.js-popup[data-block="'+target+'"]');
		
		Array.from(popups).forEach(item => {
			item.classList.remove('is-show')
		})
		elem.classList.add('is-show');
	}

	popupClose.forEach(current =>{
		current.addEventListener('click', function(e){
			Array.from(popups).forEach(item => {
				item.classList.remove('is-show')
			})
			iframe.setAttribute('src', '');
		})
	})


	// let openVideo =  D.querySelectorAll('.js-open-video');

	// openVideo.forEach(current => {
	// 	current.addEventListener('click', function(e){
	// 		e.preventDefault();

	// 		let emailVideo = Cookies.get('emailSubmitted');

	//    		if (emailVideo) { 
	//    			let urlRedirect = current.dataset.redirect;
	//    			document.location.href = urlRedirect;

	//    		} else {
	//    			let activePopup = current.dataset.link;
	// 			openPopup(activePopup);
	//    		}
	// 	})
	// })
});