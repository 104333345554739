D.addEventListener("DOMContentLoaded", function() {

	if( D.querySelector('.js-filter-wrapper')) {
		const rightCOl = D.querySelector('.js-filter-wrapper').
		  querySelector('.js-filter-wrap');
		const leftCol = D.querySelector('.js-filter-wrapper').
		  querySelector('.js-filter-list');
		const topicButtons = rightCOl.querySelectorAll('.js-filter');
		const filters = new Set;
		const blogItems = leftCol.querySelectorAll('.js-filter-block');


		topicButtons.forEach(btn => {

		  btn.addEventListener('click', () => {
		    btn.classList.toggle('is-active');
		    if (btn.classList.contains('is-active')) {
		      filters.add(btn.querySelector('span').innerHTML);
		    } else {
		      filters.delete(btn.querySelector('span').innerHTML);
		    }

		    blogItems.forEach(item => {
		      if (!filters.has(item.dataset.topic)) {
		        item.classList.add('is-hide');
		      } else {
		        if (item.classList.contains('is-hide')) {
		          item.classList.remove('is-hide')
		        }
		      }
		    });

		    if (filters.size === 0) {
		      blogItems.forEach(item => {
		        item.classList.remove('is-hide');
		      })
		    }
		  })
		});
	}

});
